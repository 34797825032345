<script setup lang="ts">
import Icon from './Icon.vue';
import { useBreadcrumb } from '~~/stores/breadcrumb';
/**
 * @displayName Breadcrumb
 */

/**
 * Uses the breadcrumb store values to generate a breadcrumb
 * The values are updated by each page
 */
const breadcrumbStore = useBreadcrumb();
</script>
<template>
  <div
    v-if="!!breadcrumbStore.items"
    class="c-breadcrumb grid grid-cols-12 gap-16 mt-15 l-content"
  >
    <ul class="col-span-9 col-start-4">
      <li
        v-for="(item, i) in breadcrumbStore.items"
        :key="i"
      >
        <NuxtLink
          v-if="!!item.to"
          :to="item.to"
          :target="item.target"
        >
          {{ $t(item.label) }}
        </NuxtLink>
        <span v-else>{{ $t(item.label) }}</span>
        <Icon
          v-if="i < breadcrumbStore.items.length - 1"
          name="chevron-right"
        />
      </li>
    </ul>
  </div>
</template>
