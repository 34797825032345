<script setup lang="ts">
import MenuDropdown from '~~/components/h/MenuDropdown.vue';

const i18nScope = useLocale('header.panel_dropdown');
const user = currentUser();

/**
 * Label that displays the organization name or user name for the dropdown
 * depending on wethever the user is an admin or a regular user
 */
const menuLabel = computed(() => user.organization?.name || user.fullName);
</script>
<template>
  <MenuDropdown
    :label="menuLabel"
    class-name="l-header__item panel-dropdown"
    icon="user"
    name="user"
    variant="basic"
  >
    <ul class="c-dropdown__list">
      <!-- Organization section -->
      <li
        v-if="!!user.organization"
        class="c-dropdown__item"
      >
        <div class="t-content">
          <div class="t-t7">
            {{ i18nScope('organization.title') }}
          </div>
          <div class="c-dropdown">
            <span class="c-button__label">{{ user.organization.name }}</span>
          </div>
        </div>
      </li>

      <li
        v-if="!user.isRoot"
        class="c-dropdown__item -divider"
      />

      <!-- Profile section -->
      <li class="c-dropdown__item">
        <div class="t-content">
          <div class="t-t7">
            {{ i18nScope('profile.title') }}
          </div>

          <div class="grid grid-flow-col gap-4">
            <div>
              <div class="text-base">
                {{ user.fullName }}
              </div>
              <a :href="`mailto:${user.email}`">{{ user.email }}</a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </MenuDropdown>
</template>
